#slider {
	padding-top: 0;
	padding-bottom: 0;
}

#page-header {
	padding-top: 2.25rem;
	padding-bottom: 3.25rem;	
	@include media-breakpoint-up(sm) {
		padding-top: 3.25rem;
		padding-bottom: 4.25rem;	
	}
	@include media-breakpoint-up(md) {
		padding-top: 4.25rem;
		padding-bottom: 6.25rem;	
	}
	@include media-breakpoint-up(lg) {
		padding-top: 6.25rem;
		padding-bottom: 8.25rem;	
	}
	@include media-breakpoint-up(xl) {
		padding-top: 7.25rem;
		padding-bottom: 9.25rem;	
	}	
	background-size: contain;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-image: url(../images/bg-page-header.png);
	h1 {
		font-size: 2rem !important;
		margin: 0 0 0 0;
		line-height: 1;
		@include media-breakpoint-up(sm) {
			font-size: 2rem !important;	
		}
		@include media-breakpoint-up(md) {
			font-size: 2.5rem !important;	
		}
		@include media-breakpoint-up(lg) {
			font-size: 3.5rem !important;
		}
		@include media-breakpoint-up(xl) {
			font-size: 5rem !important;	
		}				
		small {
			display: block;
			font-size: 30%;
			text-transform: uppercase;
			letter-spacing: .125rem;
			color: #f8f8f8;
			line-height: 1.5;
		}
	}
}

#products,
#updates {
	@include media-breakpoint-up(md) {
		background: url(../images/bg-center-stripe.png) repeat-y center top;
	}
}

.subnav {
	> li {
		@extend .nav-item;
		> a {
			@extend .nav-link;
		}
	}
}

.subnav {
	margin-bottom: 3.5rem;
	li {
		a {
			color: #666;
			border-radius: 0;
			border-left: .25rem solid #ccc;
			margin-bottom: .5rem;
			&:hover {
				border-left: .25rem solid #666;
				color: darken($gray-900, 5%);
			}
		}
		&.current_page_item,
		&.is-active {
			a {
				color: darken($gray-900, 5%);
				border-radius: 0;
				border-left: .25rem solid $blue;
				font-weight: 700;
			}
		}
	}
}

.breadcrumb {
	padding: 0 0 0 0;
	background: transparent;
	transform: translateY(-1rem);
	font-size: .75rem;
	text-transform: uppercase;
	color: $gray-500;
}

.main {
	img {
		@extend .img-fluid;
		@extend .rounded;
	}
}

.people {
   display: flex;
   flex-wrap: wrap;
   .page-title {
	   margin: 0 auto;
	   text-align: center;
   }
   .card-img-top {
	   border-radius: $border-radius $border-radius 0 0 !important;
   }
}

.people > div[class*='col-'] {
  display: flex;
}
