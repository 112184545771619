// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Color settings
$blue: #0076bc;
$yellow: #f2ea9a;
$light-blue: #a5ccd1;

// Font settings
$font-family-sans-serif: 'Montserrat', sans-serif;
$headings-color: $blue;
$headings-font-weight: 700;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;
$display1-weight: 700 !default;
$display2-weight: 700 !default;
$display3-weight: 700 !default;
$display4-weight: 700 !default;

$lead-font-weight: 400;