.navbar-light {
	&.bg-light {
		background-color: rgba(255,255,255,0.95) !important;
		box-shadow: 0 1px 3px rgba(0,0,0,0.05);
	}

	.navbar-nav-main {
		text-transform: uppercase;
		letter-spacing: .125rem;
		margin-bottom: -.5rem;
		.nav-item {
			.nav-link {
				color: $blue;
				font-weight: 600;
			}
			&.active,
			&.current-page-ancestor {
				> .nav-link {
					color: $gray-800;
				}
			}
		}
	}
	
	.navbar-nav-social {
		.nav-item {
			.nav-link {
			    font-size: 1.5rem;
			    line-height: 1rem;
			    color: $gray-500;
			    &:hover {
				    color: $blue;
			    }
			}
		}
	}
}

.navbar-brand {
	@extend .text-hide;
	width: 11.25rem;
	height: 4.6875rem;
	background-size: 11.25rem 4.6875rem;
	background-image: url(../images/thrive.png);
	background-repeat: no-repeat;
	background-position: center center;
}

.navbar {
	min-height: 6rem;
}

.dropdown-menu {
	background: lighten($yellow,3%);
	left: -.5rem;
	border: 0px none;
	margin: .25rem 0 0;
	border-radius: 0 0 $border-radius $border-radius;
	> .nav-item {
		> .dropdown-item {
			padding: .5rem 1rem !important;
		}
	}
}