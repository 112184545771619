body {
	padding-top: 6rem;
}

body,
nav,
header,
section,
footer,
.container {
	position: relative;
}

.section {
	padding: 2rem 0;
	&.section-white {
		background: #fff;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-gray {
		background: #f5f5f5;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-blue {
		background: $blue;
		color: #f8f8f8;
		hr, .page-header {
			border-color: rgba(255,255,255,0.25);
		}
		h1,h2,h3,h4,h5,h6 {
			color: $yellow;
			a {
				color: inherit;
			}
		}
		a {
			color: $yellow;
			&:hover {
				color: #f8f8f8;
			}
		}
	}
	&.section-yellow {
		background: $yellow;
		hr, .page-header {
			border-color: rgba(255,255,255,0.25);
		}
	}
	&.section-black {
		background: #000;
		color: #f8f8f8;
		hr, .page-header {
			border-color: rgba(0,0,0,0.1);
		}
	}
	&.section-lg {
		padding: 4rem 0;
	}
	&.section-full {
		padding: 6rem 0;
		min-height: 100vh;
	}
}

.center-vert {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

.widget,
.card {
	margin-bottom: 2rem;
	border: 0px none;
}

.box {
	padding: 4rem;
	height: 100%;
	text-align: center;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

hr {
	margin: 2rem 0;
}

h1,h2,h3,h4,h5,h6 {
	time {
		display: block;
		font-size: 1rem;
		text-transform: uppercase;
		color: $body-color;
		letter-spacing: .125rem;
		font-weight: 400;
	}
	a {
		color: inherit;
	}
}

.img-post {
	max-width: 60%;
	margin: 0 auto;
}

.parent-category {
	color: $light-blue;
	text-transform: uppercase;
	a {
		color: $light-blue;
		&:hover {
			color: $link-hover-color;
		}
	}
}

.nav-links {
	@extend .row;
}

.nav-previous {
	@extend .col;
	a {
		@extend .btn;
		@extend .btn-light;
	}
}

.nav-next {
	@extend .col;
	@extend .d-flex;
	@extend .justify-content-end;
	a {
		@extend .btn;
		@extend .btn-light;
	}
}

.list-columns {
	@include media-breakpoint-up(md) {
	    -webkit-column-count: 2; /* Chrome, Safari, Opera */
	    -moz-column-count: 2; /* Firefox */
	    column-count: 2;
	    -webkit-column-gap: 30px; /* Chrome, Safari, Opera */
	    -moz-column-gap: 30px; /* Firefox */
	    column-gap: 30px;	
	}
}

.gallery {
	@extend .card-deck;
	.gallery-item {
		@extend .col-12;
		@extend .col-md-6;
		@extend .col-lg-4;
		.gallery-icon {
			@extend .card;
			margin-bottom: 2rem;
			border: 0px none;
			img {
				@extend .card-img-top;
				@extend .img-fluid;
			}
		}
	}
}

.card {
	border: 0px none;
	margin-bottom: 2rem !important;
	display: block !important;
	max-width: 100%;
	.card-body {
		padding: 2rem;
	}
}

.card-img-top,
.card-img-bottom,
.card-img-middle {
	width: auto !important;
	height: auto !important;
	max-width: 100% !important;
}

.card-img-middle {
	border-radius: 0 0 0 0;
}

.card-deck {
	@include media-breakpoint-up(xs) {
		column-count: 1 !important;
	}
	@include media-breakpoint-up(sm) {
		column-count: 2 !important;
	}
	@include media-breakpoint-up(lg) {
		column-count: 3 !important;
	}
}

.row-cat {
   display: flex;
   flex-wrap: wrap;
}

.row-cat > div[class*='col-'] {
  display: flex;
}

.bg-yellow {
	background: $yellow;
}