#info {
	.menu {
		.menu-item {
			a {
				padding: .5rem 0;
			}
			&.active {
				a {
					color: $yellow;
					font-weight: 700;
				}
			}
		}
	}
	a {
		color: #f8f8f8;
		&:hover {
			color: $yellow;
		}		
	}
	h1,h2,h3,h4,h5,h6 {
		color: $yellow;
		a {
			color: inherit;
		}
	}
	.nav {
		&.nav-social {
			margin: -.5rem -.5rem;
			.nav-item {
				.nav-link {
					padding: .5rem .5rem;
				}
			}
		}
	}
	small {
		text-transform: uppercase;
		font-size: .5rem;
		line-height: 1.5rem;
	}
}

.menu {
	@extend .nav;
	@extend .flex-column;
}

.menu-item {
	@extend .nav-item;
	a {
		@extend .nav-link;
	}
}


