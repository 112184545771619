#sidebar {
	.nav {
		.nav-item {
			.nav-link {
				padding: .5rem 0;
			}
			&.active {
				.nav-link {
					color: $body-color;
					font-weight: 700;
				}
			}
		}
	}
}